
import { Component, Vue } from 'vue-property-decorator';
import HelpLists from "@/components/board/HelpLists.vue";

@Component({
  components: {
    HelpLists,
  },
})
export default class HelpView extends Vue {
  $refs: Vue['$refs'] & {
    helpView: HelpLists;
  };
  async mounted() {
    await this.$refs.helpView.load();
  }
}
